import React from 'react';
import "./you.css";

function You() {
    return(
        <div className="you">
            <h3>We Server Our Customers!</h3>
            <p>Our customers are our number one priority. We provide services that ranges from training to implementation on each project.
                   
            </p>

        </div>
    )
}
export default You;