import React from 'react';
import "./about.css"
import CustomerForm from '../home/customer-form';

function About() {
    return(
        <div className="container">
            <div className="about"> 
                <p>Since its inception, Ackee Design wanted to find a more efficient approach to software customization.  
                    Traditionally, companies leveraged existing paradigms but Cheryl Williams wanted to identify a more dynamic and less invasive solution.
                    This let to an approach of customer first, callaborative  </p>

                    <h3>Services</h3>
                    <ul>
                        <li>Develop Web Applications</li>
                        <li>Java Development</li>
                        <li>Support and Maintain Applications</li>
                        <li>Work with teams on SDLC</li>
                        <li>Training Web Development</li>
                        <li>Training Java</li>
                    </ul>
                    <h3>Contact Ackee Today!</h3>
                    <CustomerForm/>

                <card title="Call Us Today!" message="205-999-1060">

                </card>
            </div>
        </div>
    )
}
export default About;