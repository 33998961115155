import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';


function CustomerService() {
    const [data, setData] = useState([]);

    async function getCustomers() {
        const result = await axios(
            "http://localhost:8080/customers",
        );
        setData(result.data);
    }

    useEffect(() => {
         getCustomers();
      }, []);
      return <div>
          {/* {JSON.stringify(data)} */}
       <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                        <th>Id</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Address</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Zip</th>
                        <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                   
                  {data.map(customer => {
                      return(
                        <tr key="customer.id">
                            <td>{customer.id}</td>
                            <td>{customer.fName}</td>
                            <td>{customer.lName}</td>
                            <td>{customer.address}</td>
                            <td>{customer.city}</td>
                            <td>{customer.state}</td>
                            <td>{customer.zip}</td>
                            <td>{customer.total}</td>
                        </tr>
                      )
                    
                  })
                    
                    }

                    </tbody>
                </Table> 
      </div>























































}
export default CustomerService;