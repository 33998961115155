
import React, { Component } from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CustomerService from '../_service/customer-service';
import CustomerForm from './customer-form';

class CollapsePage extends Component {

render() {
    return (
        <Accordion>
        {/* <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Click To View Rest Get Request!
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div>
                <CustomerService />
                
              </div>
</Card.Body>
          </Accordion.Collapse>
        </Card> */}
        <Card>
          <Card.Header>
            <Accordion.Toggle className="fonts" as={Button} variant="link" eventKey="1">
              Click to Request More Information!
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <CustomerForm />
              
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  }
}

export default CollapsePage;
