import React, {useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import Quote from './quote'
import './home.css'


function CustomerForm(props) {
    
    const [customer, setCustomer] = useState({
        fName: '',
        lName: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        email: '',
        phone: ''
    })

     const handleChange = (event) => {
        console.log("I made it")
        console.log(event.target.value);

        setCustomer[event.target.name] = event.target.value;
        console.log("Customer "+JSON.stringify(customer))
     }

    const createCustomerHandler = (evt) => {
        axios({
            method: 'post',
            url: 'http://localhost:8080/create/customer',
            data: customer
        });
    }

    return(
        <Form onSubmit={createCustomerHandler} name="customerForm" id="customerForm"> 
            <Form.Row>
                <Form.Group as={Col}>
                <Form.Label>First Name</Form.Label>
                <Form.Control name="fName" onChange={handleChange} placeholder="Enter First Name" />
                </Form.Group>

                <Form.Group as={Col}>
                <Form.Label>Last Name</Form.Label>
                <Form.Control name="lName" onChange={handleChange} placeholder="Enter Last Name" />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control name="email" onChange={handleChange} placeholder="Enter Email Address" />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridPassword">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control name="phone" onChange={handleChange} placeholder="Enter Phone Number" />
                </Form.Group>
            </Form.Row>

            <Form.Group controlId="formGridAddress1">
                <Form.Label>Address</Form.Label>
                <Form.Control name="address" onChange={handleChange} placeholder="1234 Main St" />
            </Form.Group>

            <Form.Group controlId="formGridAddress2">
                <Form.Label>Address 2</Form.Label>
                <Form.Control name="address2" onChange={handleChange} placeholder="Apartment, studio, or floor" />
            </Form.Group>

            <Form.Row>
                <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>City</Form.Label>
                <Form.Control name="city"  onChange={handleChange}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridState">
                <Form.Label>State</Form.Label>
                <Form.Control name="state" onChange={handleChange} as="select">
                    <option>Choose...</option>
                    <option>AL</option>
                    <option>NY</option>
                    <option>VA</option>
                    <option>FL</option>
                </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridZip">
                <Form.Label>Zip</Form.Label>
                <Form.Control name="zip" onChange={handleChange} />
                </Form.Group>
            </Form.Row>

            <Button variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    )
}
export default CustomerForm;