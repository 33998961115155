import React from 'react';
import Card from 'react-bootstrap/Card';
import './home.css';

function InfoCard(props) {
    return(
        <div className="infoCard">
            <Card style={{ width: '30rem', height: '45rem' }}>
            <Card.Img variant="top" src={props.picture} style={{height: '30rem' }}/>
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <Card.Text>
                    {props.message} 
                </Card.Text>
            </Card.Body>
            </Card>
    </div>
    )
}
export default InfoCard;