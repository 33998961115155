import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';


const CarouselPage = () => {
  return (
    <div>
        <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(35).jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>Collaboration!</h3>
            <p>We collaborate to deliver ahead of schedule!</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(33).jpg"
            alt="Third slide"
          />
      
          <Carousel.Caption>
            <h3>Contact Us for a consultation</h3>
            <p>Before we embark on a project we get to know your team!</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://mdbootstrap.com/img/Photos/Slides/img%20(31).jpg"
            alt="Third slide"
          />
      
          <Carousel.Caption>
            <h3>Foresight</h3>
            <p>Years of experience in IT gives us a diverse perspective!</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
  </div>
  );
}

export default CarouselPage;