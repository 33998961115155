import React from 'react';
import Container from 'react-bootstrap/Container';
import CarouselPage from './carousel.js';
import CollapsePage from './accordian.js';
import InfoCard from './card.js';
import sdlc from '../images/sdlc.png';
import mfingwater from '../images/mfingwater.jpg';
import backgrnd from '../images/backgrnd.jpg';
import shower from '../images/shower.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'


function Home() {
    return (
    <div>
        <CarouselPage/> 
        <CollapsePage/> 
        <Row>
           <Col md={6}><Image className="sdlc-img" src={sdlc} fluid /></Col>
           <Col md={6}>
               <div className="sdlc-info fonts">
                   From inception to delivery we collaborate with your
                    teams to deliver the best-in-class solution. 
                </div>
            </Col>
        </Row>
        <Row>
        </Row>
        <Row className="shadow-lg p-3 mb-5 bg-white rounded">
            <Col md={4}>
                <div >
                    <InfoCard picture={backgrnd} title="Integrate Inovation into Every Solution!" message="Work life balance matters! Our team collaborates with your organization to find the best-in-class solutions."/>
                </div>
            </Col>
            <Col md={4}>
                <div>
                    <InfoCard picture={shower} title="Think Outside The Box!" message="Our team coalesces life and experience to offer out-of-the-box solutions!"/>
                </div>
            </Col>
            <Col md={4}>
                <div >
                    <InfoCard picture={mfingwater} title="Together The Path is Ours!" message="Work life balance is Important!
                            We send in a team of professionals to Do the work!"/>
                </div>
            </Col>
        </Row>
       
        
    </div>
    )

}

export default Home;